.mappedin-marker > div {
  height: 3rem !important;
  width: 3rem !important;
}

.mappedin-tooltip > div {
  border-radius: 9999px;
}

.mappedin-tooltip__content > div {
  height: 3rem !important;
  width: 3rem !important;
}

.mappedin-tooltip__content > div > svg {
  height: 1.25rem !important;
  width: 1.25rem !important;
}
