.hg-theme-default .hg-button {
  border-bottom: 0.0625rem solid #b5b5b5;
  border-radius: 0.3125rem;
  box-shadow: 0 0 0.1875rem -0.0625rem rgb(0 0 0 / 30%);
  height: 2.5rem;
  padding: 0.3125rem;
}

.react-simple-keyboard {
  background-color: transparent;
}
